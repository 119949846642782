<template>
  <!-- width="50%" -->
  <v-card class="mt-5" width="100%">
    <v-card-title> Perfis </v-card-title>
    <v-card-subtitle class="text-left">
      Lista de perfis configurados
    </v-card-subtitle>
    <v-divider style="border: solid 2px" class="orange darken-1"></v-divider>
    <div style="position: relative">
      <v-fab-transition>
        <v-tooltip top max-width="220">
          <template v-slot:activator="{ on, attrs }">
            <!-- $store.state.banksProfileRegisterCbo.length
                  ? ''
                  : 'disabAddButton', -->
            <v-btn
              :class="['mr-3']"
              @click="toogleRegisterProfileDilog"
              v-bind="attrs"
              v-on="on"
              color="orange darken-1"
              fab
              dark
              small
              absolute
              top
              right
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>{{
            !$store.state.banksProfileRegisterCbo.length
              ? "Primeiramente configure um banco no box ao lado."
              : "Criar novo perfil"
          }}</span>
        </v-tooltip>
      </v-fab-transition>
    </div>
    <v-card-text class="">
      <v-data-table
        :headers="headers"
        :items="profiles"
        :items-per-page="5"
        class="elevation-0"
        no-data-text="Nenhum perfil cadastrado"
      >
        <template v-slot:item.profile_name="{ item }">
          <div>{{ item.profile_name }}</div>
          <div>
            <small
              ><b>{{ item.bank.bank_name }}</b></small
            >
          </div>
        </template>
        <template v-slot:item.profile_data.indicadorPix="{ item }">
          {{ item.profile_data.indicadorPix == "S" ? "Sim" : "Não" }}
        </template>
        <!-- <template v-slot:item.profile_data.desconto.tipo="{ item }">
                    {{ setDiscountTxt(item.profile_data.desconto.tipo) }}
                </template> -->

        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primaryNew" dark icon v-bind="attrs" v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list dense rounded class="">
              <v-subheader class="text-subtitle-2">
                <b class="primaryNew--text">
                  {{ item.profile_name }}
                </b></v-subheader
              >
              <v-divider class="mb-2"></v-divider>
              <!-- <v-list-item-group color="primaryNew" class=""> -->
              <v-list-item @click="editProfile(item)">
                <v-list-item-icon class="mr-4">
                  <v-icon>drive_file_rename_outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Editar perfil</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="confirmDelProfile(item)">
                <v-list-item-icon class="mr-4">
                  <v-icon>delete_forever</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Excluir perfil</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- </v-list-item-group> -->
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>
    <ProfileRegister
      :verifySteps="null"
      :getProfiles="getProfiles"
      :editProfileData="editProfileData"
      :toogleRegisterProfileDilog="toogleRegisterProfileDilog"
      :registerProfileDilog="registerProfileDilog"
    />
  </v-card>
</template>

<script>
import ProfileRegister from "../../../../components/clients/billets/profiles/ProfileRegister.vue";
export default {
  methods: {
    // async getConfiguredBanks(loader) {
    //     var obj = {
    //         noLoader: loader,
    //         url: "/api/v1/title/user/bank/getUserBank",
    //         query: null,
    //         method: "get",
    //     };
    //     await this.$store.dispatch("request/doRequest", obj).then((response) => {
    //         this.banksProfileRegisterCbo = [
    //             ...response.data.map((obj) => {
    //                 return {
    //                     number_bank: obj.bank.number_bank,
    //                     pg_bank_favoured_id: obj.id,
    //                     bank_name: obj.bank.bank_name,
    //                 };
    //             }),
    //         ];
    //         this.banks = [...response.data];
    //     });
    // },
    async getProfiles(loader) {
      var obj = {
        noLoader: loader,
        url: "/api/v1/title/user/profile/get-all",
        query: null,
        method: "get",
      };
      await this.$store.dispatch("request/doRequest", obj).then((response) => {
        this.profilesCbos = [
          ...response.data.map((prf) => {
            return { id: prf.id, name: prf.profile_name };
          }),
        ];
        this.profiles = [...response.data];
      });
    },
    confirmDelProfile(data) {
      let obj = {
        message: `Você deseja excluir o perfil <b>${data.profile_name}</b>?`,
        title: "Excluir",
        callback: (_) => {
          this.delProfile(data);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    delProfile(data) {
      let obj = {
        url: "/api/v1/title/user/profile/delete",
        query: {
          id: data.id,
        },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        let opts = {
          message: `Perfil removido com sucesso.`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
        let index = this.profiles.findIndex(
          (profile) => profile.id === data.id
        );
        this.profiles.splice(index, 1);
      });
    },
    setDiscountTxt(data) {
      // console.log(data);
      let tipoDesconto = [
        "Sem desconto",
        "Valor fixo até a data informada",
        "Percentual até a data informada",
        "Desconto por dia antecipado",
      ];
      return tipoDesconto[1];
    },
    editProfile(data) {
      this.editProfileData = { ...data };
      this.registerProfileDilog = true;
    },
    toogleRegisterProfileDilog() {
      //   if (!this.$store.state.banksProfileRegisterCbo.length) {
      //     return;
      //   }
      this.editProfileData = null;
      this.registerProfileDilog = !this.registerProfileDilog;
    },
  },
  data() {
    return {
      registerProfileDilog: false,
      editProfileData: null,
      // banksProfileRegisterCbo: [],
      profiles: [],
      headers: [
        {
          text: "Nome do perfil",
          value: "profile_name",
          align: "left",
          sortable: false,
        },
        {
          text: "Pix",
          value: "profile_data.indicadorPix",
          align: "left",
          sortable: false,
        },

        // {
        //     text: "Desconto",
        //     value: "profile_data.desconto.tipo",
        //     align: "left",
        //     sortable: false,
        // },
        {
          text: "Ações",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getProfiles();
    // this.getConfiguredBanks(true)
  },
  components: {
    ProfileRegister,
  },
};
</script>

<style>
.orange.darken-1.disabAddButton {
  background-color: #ffc072 !important;
  cursor: auto;
}
</style>